import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import { Link } from 'gatsby';

import Layout from '../../components/Layout';
import Checkmark from '../../components/atoms/Checkmark';
import CFS from '../../components/molecules/CFS';

import imgTb1 from '../../assets/images/pages/orientacion-profesional/L4L.svg';
import imgTb2 from '../../assets/images/pages/orientacion-profesional/Elemental-online-barcelona.svg';
import imgTb3 from '../../assets/images/pages/orientacion-profesional/Residencia-universitaria-Sarriá.svg';
import imgTb4 from '../../assets/images/pages/orientacion-profesional/Hong-Kong-Tennis-&-Golf-academy.svg';
import imgTb5 from '../../assets/images/pages/orientacion-profesional/Taiwan.png';

import imgTb6 from '../../assets/images/pages/orientacion-profesional/BCNlanguageslogo.png';
import imgTb7 from '../../assets/images/pages/orientacion-profesional/Camino-logo.jpeg';
import imgTb8 from '../../assets/images/pages/orientacion-profesional/Speakeasylogo.jpeg';
import imgBenefit1 from '../../assets/images/pages/orientacion-profesional/imgFirst.svg';
import imgBenefit2 from '../../assets/images/pages/orientacion-profesional/imgSecond.svg';
import imgBenefit3 from '../../assets/images/pages/orientacion-profesional/imgThird.svg';
import imgBenefit5 from '../../assets/images/pages/orientacion-profesional/imgFourth.svg';
import imgPhotoRight from '../../assets/images/pages/orientacion-profesional/secImgRight.png';
import IconArrowLeft from '../../assets/images/pages/orientacion-profesional/arrow-left.inline.svg';

import '../../assets/styles/pages/orientacion-profesional.scss';

const OrientacionProfesional = () => {
  const PrevArrow = ({ onClick }) => {
    return (
      <div onClick={onClick} className="slider-arrow slider-arrow--prev">
        <IconArrowLeft />
      </div>
    );
  };
  const NextArrow = ({ onClick }) => {
    return (
      <div onClick={onClick} className="slider-arrow slider-arrow--next">
        <IconArrowLeft />
      </div>
    );
  };
  const settings = {
    infinite: true,
    slidesToShow: 5,
    autoplaySpeed: 5000,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Layout
      title="Orientación Profesional | ELE Barcelona"
      description="Tenemos un servicio de orientación profesional para ayudarte a encontrar trabajo como profesor de español. Hacemos tu CV y preparamos la entrevista de trabajo."
    >
      <div className="orientacion-profesional">
        <CFS title="Orientación profesional" />
        <section className="second-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  Ábrete a un nuevo mundo de posibilidades
                </h2>
                <p className="c-text-18 description second-section__l">
                  La enseñanza del idioma español tiene una gran demanda en todo el mundo. Entre
                  otras innumerables trayectorias profesionales y potenciales, tu curso FELE
                  Intensivo te permite:
                </p>
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Trabaja en una academia de idiomas</h3>
                      <p className="c-text-14">
                        Da clases de español a adultos, adolescentes o niños en las mejores
                        academias de idiomas y escuelas de ELE, tanto en Barcelona como en cualquier
                        otro lugar del mundo.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Imparte clases de español de negocios</h3>
                      <p className="c-text-14">
                        El español es un idioma vehicular en los negocios internacionales, lo que se
                        traduce en oportunidades de trabajo para dar clases en empresas.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Da clases particulares</h3>
                      <p className="c-text-14">
                        Establece tu propio horario como profesor de español y enseña cuando quieras
                        y donde quieras.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Encuentra trabajo de profesor online</h3>
                      <p className="c-text-14">
                        Gracias a las maravillas de internet encuenta millones de estudiantes
                        potenciales a solo unos clics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <div className="right__img">
                    <img src={imgPhotoRight} alt="imgPhotoRight" />
                  </div>
                  <div className="notices">
                    <div className="notice">
                      <h3 className="notice__title">2 semanas</h3>
                      <p className="notice__text">
                        Tiempo promedio que tardan los graduados en encontrar trabajo como profesor
                        de español
                      </p>
                    </div>
                    <div className="notice">
                      <h3 className="notice__title">98%</h3>
                      <p className="notice__text">
                        De todos los estudiantes del FELE desde 2016 aprobaron con éxito.
                      </p>
                    </div>
                    <div className="notice">
                      <h3 className="notice__title">1300€</h3>
                      <p className="notice__text">
                        Ingreso mensual promedio para nuevos profesores de español.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h2 className="third-section__title c-title-34">
                  ¿Qué está incluido en el precio?
                </h2>
                <p className="third-section__description c-text-15">
                  Aquí en ELE Barcelona no solo te proporcionamos una educación líder en el sector
                  de la enseñanza del español. También te equipamos con las habilidades, la
                  orientación y las conexiones que necesitas para encontrar un trabajo remunerado a
                  pocas semanas de completar tu formación.
                </p>
              </div>
              <div className="col-md-7">
                <div className="board">
                  <h3 className="board__title">Nuestro apoyo post curso incluye:</h3>
                  <Checkmark>Sesión de orientación al mercado laboral.</Checkmark>
                  <Checkmark>Taller de preparación de CV.</Checkmark>
                  <Checkmark>Preparación y práctica de entrevistas.</Checkmark>
                  <Checkmark>Acceso exclusivo a nuestra base de datos de empleadores.</Checkmark>
                  <Checkmark>Acceso privilegiado a la bolsa de trabajo de ELE Barcelona.</Checkmark>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section c-section-py-80">
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              charset="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
          </Helmet>
          <div className="c-reviews container">
            <div className="row">
              <div className="col-sm-7 col-lg-5">
                <h2 className="c-title-34">Los graduados de ELE Barcelona trabajan en:</h2>
              </div>
            </div>
            <Slider {...settings}>
              <div className="partner">
                <img src={imgTb1} alt="Languages4Life" />
                <p className="partner__name">Languages4Life</p>
              </div>
              <div className="partner">
                <img src={imgTb2} alt="Elemental online barcelona" />
                <p className="partner__name">Elemental online barcelona</p>
              </div>
              <div className="partner">
                <img src={imgTb3} alt="Residencia universitaria Sarriá" />
                <p className="partner__name">Residencia universitaria Sarriá</p>
              </div>
              <div className="partner">
                <img src={imgTb4} alt="Hong Kong Tennis & Golf academy." />
                <p className="partner__name">Hong Kong Tennis & Golf academy.</p>
              </div>
              <div className="partner">
                <img src={imgTb5} alt="DC Languages School" />
                <p className="partner__name">DC Languages School</p>
              </div>
              <div className="partner">
                <img src={imgTb6} alt="BCN Languages" />
                <p className="partner__name">BCN Languages</p>
              </div>
              <div className="partner">
                <img src={imgTb8} alt="Speakeasy" />
                <p className="partner__name">Speakeasy</p>
              </div>
              <div className="partner">
                <img src={imgTb7} alt="Camino" />
                <p className="partner__name">Camino</p>
              </div>
            </Slider>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default OrientacionProfesional;
